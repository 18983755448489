import React from "react"
import {graphql, Link} from "gatsby"
import SEO from "../components/seo";
import Layout from "../components/layout";

export default function MarkdownRemarkFrontmatter__slug({
                                                            data, // this prop will be injected by the GraphQL query below.
                                                        }) {
    const {markdownRemark} = data // data.markdownRemark holds your post data
    const {frontmatter, html} = markdownRemark
    return (
        <Layout>
            <SEO
                keywords={[`domain`, `name`, `generator`, `tool`]}
                title={frontmatter.title + " - Domainprinter"}
            />
            <section>
                <div className="sm:px-6 px-5 pb-16 dark:bg-gray-800 lg:max-w-4xl mx-auto">

                    <div className="sm:pl-4 container w-full pb-16">
                        <div className="flex flex-row pb-2 font-bold">
                            <Link to={"/blog"}>Blog →</Link> <span className="pl-2 font-normal"> {frontmatter.title}</span>
                        </div>
                        <h1 className="text-4xl font-extrabold tracking-tight text-slate-900 dark:text-slate-200 md:text-4xl">{frontmatter.title}</h1>
                        <p className="meta pt-2">{frontmatter.meta}</p>
                        <h3 className="pt-1">by <i>Domainprinter team</i> | Updated {frontmatter.date}</h3>
                        <br></br>
                        <div
                            className="blog-post-content"
                            dangerouslySetInnerHTML={{__html: html}}
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        meta
      }
    }
  }
`